import { Routes, Route, Navigate } from "react-router-dom";
import { ValidateScreen } from "../components/clientes/ValidateScreen";
import { Error404Screen } from "../components/feedback/Error404Screen";
import { NewPasswordScreen } from "../components/clientes/NewPasswordScreen";
import { EstadoScreen } from "../components/feedback/EstadoScreen";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/clientes/validate" element={<ValidateScreen />} />
      <Route path="/clientes/new-password" element={<NewPasswordScreen />} />
      <Route path="/clientes/resume" element={<EstadoScreen />} />
      <Route path="/" element={<Navigate to="/clientes/validate" />} />

      <Route path="/*" element={<Error404Screen />} />
      {/* <Route path="/*" element={<Navigate to="/login" />} /> */}
    </Routes>
  );
};
