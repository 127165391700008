import { useEffect, useState } from "react";
import { Col, Container, Image, Navbar, Row } from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import success_icon from "../../assets/images/success-icon.svg";
import error_icon from "../../assets/images/error-icon.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./estado.css";

export const EstadoScreen = () => {
  const { state } = useLocation();
  const { status, source } = state || { status: "error", source: 1 };

  const [estado, setEstado] = useState(status === "ok" ? true : false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const secondsRedirect = 5;
  const [timeLeft, setTimeLeft] = useState(secondsRedirect);

  //Creamos un intervalo que se ejecuta cada segundo
  useEffect(() => {
    try {
      const timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          if (source != 2) {
            window.location.replace(process.env.REACT_APP_URL_PORTAL_CLIENTES);
          }
        }
      }, 1000);
      //Limpiamos el intervalo cuando el componente se desmonta
      return () => clearInterval(timer);
    } catch (e) {
      console.log(e);
    }
  }, [timeLeft]);

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar collapseOnSelect expand="lg" className="menu px-1">
          <Navbar.Brand>
            <img
              src={petroprix_logo}
              className="d-inline-block align-top"
              alt={t("menu.logo.text")}
            />
          </Navbar.Brand>
        </Navbar>
      </Container>
      <Container className="content-estado-page">
        <Row>
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left estado-page-logo"
              alt="Petroprix | Zona clientes"
            />
          </header>
        </Row>
        <Row className="resume-content">
          <Image
            src={estado ? success_icon : error_icon}
            className="resume-estado-img"
          />
          <div className="resume-estado">
            <p className="resume-estado-text mt-3">
              {estado ? t("resume.estado.ok") : t("resume.estado.nok")}
            </p>
            {source != 2 ? (
              <>
                <p className="resume-estado-info mt-3">
                  {t("resume.estado.info")} {timeLeft}{" "}
                  {t("resume.estado.info.2")}
                </p>
                <p className="resume-estado-info-adicional mt-2">
                  {t("resume.estado.info.b")}
                </p>
              </>
            ) : (
              <p className="resume-estado-info mt-3">
                {t("resume.estado.info-app")}
              </p>
            )}
            {source != 2 && (
              <div className="resume-estado-btn-content mt-2">
                <button
                  className="resume-estado-info btn btn-primary"
                  onClick={() => {
                    window.location.replace(
                      process.env.REACT_APP_URL_PORTAL_CLIENTES
                    );
                  }}
                >
                  {t("resume.estado.btn")}
                </button>
              </div>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};
