import { AppRouter } from "./routers/AppRouter";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const SingUpService = () => {
  // const [language, setLanguage] = useState('en')

  // i18n.changeLanguage(language)

  // console.log(i18n.language)

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <AppRouter />
    </>
  );
};
