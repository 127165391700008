// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-estado-page {
  padding-top: 3.06em;
}

.estado-page-logo {
  float: left;
  margin-top: 3.07em;
}

.resume-content {
  margin-top: 6em;
}

.resume-estado-img {
  margin: 0 auto;
  width: 7em;
}

.resume-estado-text,
.resume-estado-info,
.resume-estado-info-adicional {
  text-align: center;
}
.resume-estado-text {
  font-size: 2.3em;
  font-weight: 700;
}

.resume-estado-info {
  font-size: 0.9em;
  font-weight: 800;
}

.resume-estado-info-adicional {
  font-size: 0.8em;
  font-weight: 400;
}

.resume-estado-btn-content {
  width: 100%;
  text-align: center;
}

.resume-section {
  margin-top: 5.12em;
}

.resume-text {
  font-weight: 600;
}

.resume-text-red {
  color: #db0b27;
}

@media (min-width: 992px) {
  .estado-text {
    margin-top: 4em;
  }

  .resume-content {
    margin-top: 1em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/feedback/estado.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;;;EAGE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".content-estado-page {\n  padding-top: 3.06em;\n}\n\n.estado-page-logo {\n  float: left;\n  margin-top: 3.07em;\n}\n\n.resume-content {\n  margin-top: 6em;\n}\n\n.resume-estado-img {\n  margin: 0 auto;\n  width: 7em;\n}\n\n.resume-estado-text,\n.resume-estado-info,\n.resume-estado-info-adicional {\n  text-align: center;\n}\n.resume-estado-text {\n  font-size: 2.3em;\n  font-weight: 700;\n}\n\n.resume-estado-info {\n  font-size: 0.9em;\n  font-weight: 800;\n}\n\n.resume-estado-info-adicional {\n  font-size: 0.8em;\n  font-weight: 400;\n}\n\n.resume-estado-btn-content {\n  width: 100%;\n  text-align: center;\n}\n\n.resume-section {\n  margin-top: 5.12em;\n}\n\n.resume-text {\n  font-weight: 600;\n}\n\n.resume-text-red {\n  color: #db0b27;\n}\n\n@media (min-width: 992px) {\n  .estado-text {\n    margin-top: 4em;\n  }\n\n  .resume-content {\n    margin-top: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
