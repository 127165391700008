import { useCallback, useRef, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import password_img from "../../assets/images/password-img.svg";
import password_hide_icon from "../../assets/images/password-hide-icon.svg";
import password_show_icon from "../../assets/images/password-show-icon.svg";
import "./clientes.css";
import axios from "axios";
import { jwtVerify } from "jose";
import ReCAPTCHA from "react-google-recaptcha";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom/dist";
import { jwtDecode } from "jwt-decode";
import { MiniLoader } from "../ui/MiniLoader";
import axiosApi from "../../axios/axios-api";
import { HelpInfo } from "../ui/HelpInfo";

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
const URL_PASSWORD_RECOVERY = "/cliente/confirmarRestablecer";

export const NewPasswordScreen = () => {
  const { t } = useTranslation();

  const [validated, setValidated] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [tkValid, setTkValid] = useState(false);
  const [tk, setTk] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorForm, setErrorForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [intents, setIntents] = useState(0);
  const [iconShowPassword, setIconShowPassword] = useState(password_hide_icon);
  const [passwordRepeatShow, setPasswordRepeatShow] = useState(false);
  const [iconShowPasswordRepeat, setIconShowPasswordRepeat] =
    useState(password_hide_icon);
  const [captchaValid, setCaptchaValid] = useState(null);
  const [source, setSource] = useState(null);
  const [telefonoContacto, setTelefonoContacto] = useState(t("ayuda.telefono"));
  const [emailContacto, setEmailContacto] = useState(t("ayuda.email"));

  const navigate = useNavigate();
  const captchaRef = useRef(null);

  const onChange = () => {
    if (captchaRef.current.getValue()) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const fetchInfoContacto = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API) {
        const response = await axiosApi.get("infoPetroprix", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setTelefonoContacto(data.telefono);
          setEmailContacto(data.email_informacion);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchInfoContacto();
  }, []);

  const handleShowPassword = () => {
    setPasswordShow(!passwordShow);
    passwordShow
      ? setIconShowPassword(password_hide_icon)
      : setIconShowPassword(password_show_icon);
  };

  const handleShowPasswordRepeat = () => {
    setPasswordRepeatShow(!passwordRepeatShow);
    passwordRepeatShow
      ? setIconShowPasswordRepeat(password_hide_icon)
      : setIconShowPasswordRepeat(password_show_icon);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tk = urlParams.get("tk");

    if (!tk) {
      navigate("/404");
    } else {
      const verifyToken = async () => {
        try {
          const keyBytes = new TextEncoder().encode(
            process.env.REACT_APP_RECOVERY_JWT_SECRET_KEY
          );

          const { payload } = await jwtVerify(tk, keyBytes, {
            algorithms: ["HS256"],
          });
          setSource(payload.source);
          setTk(tk);
          setTkValid(true);
        } catch (error) {
          console.error(error);
          setTkValid(false);
        }
      };

      verifyToken();
    }
  }, []);

  const validatePassword = (password) => {
    // Valida que la contraseña tenga entre 8 y 15 caracteres, una letra mayúscula, una letra minúscula y un número
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}$/;
    return re.test(password);
  };

  const handleSubmit = async (event) => {
    const password = document.getElementById("password");
    const passwordRepeat = document.getElementById("password-repeat");

    setLoading(true);
    event.preventDefault();
    event.stopPropagation();
    setIntents(intents + 1);

    let dataValid = {
      password: false,
      passwordRepeat: false,
    };

    if (!validatePassword(password.value)) {
      password.setCustomValidity(" ");
      dataValid.password = false;
    } else {
      password.setCustomValidity("");
      dataValid.password = true;
    }

    if (passwordRepeat.value !== password.value) {
      passwordRepeat.setCustomValidity(" ");
      dataValid.passwordRepeat = false;
    } else {
      passwordRepeat.setCustomValidity("");
      dataValid.passwordRepeat = true;
    }

    setValidated(true);

    if (!dataValid.password || !dataValid.passwordRepeat) {
      setLoading(false);
      return;
    } else {
      if (!captchaValid) {
        setLoading(false);
        setCaptchaValid(false);
        return;
      }

      let data = {
        token: tk,
        password: password.value,
        match_password: passwordRepeat.value,
        captcha: captchaRef.current.getValue(),
      };
      try {
        if (process.env.REACT_APP_URL_API) {
          const response = await axiosApi.post(URL_PASSWORD_RECOVERY, data);
          if (response?.data?.code === 200) {
            setLoading(false);
            setErrorMsg(null);
            setErrorForm(false);
            navigate("/clientes/resume", {
              state: { status: "ok", source: source },
            });
          } else {
            setErrorMsg(response.data?.message);
            setErrorForm(true);
            setLoading(false);
            console.warn(response.data?.message);
            captchaRef.current.reset();
            setCaptchaValid(null);
          }
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        setErrorMsg(t("error.form.general"));
        setErrorForm(true);
        captchaRef.current.reset();
        setCaptchaValid(null);
      }
    }
  };

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="menu px-1"
          style={{ display: "flex" }}
        >
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top"
              alt={t("validate.menu.text")}
            />
          </Navbar.Brand>
          <HelpInfo telefono={telefonoContacto} email={emailContacto} />
        </Navbar>
      </Container>
      <Container className="content-validate-page">
        <Container className="page-header-container d-none d-lg-flex">
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left validate-page-logo"
              alt={t("validate.menu.text")}
            />
          </header>
          <HelpInfo
            className={"d-none d-lg-block"}
            telefono={telefonoContacto}
            email={emailContacto}
          />
        </Container>
        <Container className="validate-container py-1">
          <Row className="content-section form-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("validate.header.h1")}
                <span className="red-text">{t("validate.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image src={password_img} fluid="true" className="mt-5" />
            </Col>
            <Col xs={12} lg={{ span: 4, offset: 2, order: 3 }}>
              {tkValid ? (
                <>
                  <h2 className="mt-5">{t("new-password.h2")}</h2>
                  {!errorForm && intents === 0 && (
                    <Alert variant="primary-login">
                      {t("new-password.alert")}
                      <b>{t("new-password.alert.b")}</b>.
                    </Alert>
                  )}

                  {captchaValid === false ? (
                    <Alert variant="danger-login">
                      <b>{t("error.form.captcha.not-valid")}</b>
                    </Alert>
                  ) : (
                    errorForm &&
                    intents > 0 && <Alert variant="danger">{errorMsg}</Alert>
                  )}
                  <Form
                    noValidate
                    validated={validated}
                    className="validate-form"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <FloatingLabel
                      label={t("validate.form.password")}
                      className="mt-3"
                    >
                      <Form.Control
                        type={passwordShow ? "text" : "password"}
                        id="password"
                        placeholder={t("validate.form.password")}
                        required
                        minLength="8"
                        maxLength="15"
                      />
                      <span
                        toggle="#password-field"
                        className="toggle-password"
                        onClick={handleShowPassword}
                      >
                        <img src={iconShowPassword} className="passwordIcon" />
                      </span>
                      <Feedback type="invalid">
                        {t("validate.form.password.invalid")}
                      </Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      label={t("validate.form.password-repeat")}
                      className="mt-3"
                    >
                      <Form.Control
                        type={passwordRepeatShow ? "text" : "password"}
                        id="password-repeat"
                        minLength="8"
                        maxLength="15"
                        placeholder={t("validate.form.password-repeat")}
                        required
                      />
                      <span
                        toggle="#password-field"
                        className="toggle-password"
                        onClick={handleShowPasswordRepeat}
                      >
                        <img
                          src={iconShowPasswordRepeat}
                          className="passwordIcon"
                        />
                      </span>
                      <Feedback type="invalid">
                        {t("validate.form.password-repeat.invalid")}
                      </Feedback>
                    </FloatingLabel>

                    <div className="mt-4 d-flex recaptcha">
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey={SITE_KEY}
                        onChange={onChange}
                        required
                      />
                    </div>

                    <div className="d-grid gap-2 mt-5">
                      <Button
                        variant="primary"
                        type="submit"
                        size="lg"
                        id="form-validate-btn"
                        className="form-validate-btn"
                        disabled={loading}
                      >
                        {loading ? (
                          <MiniLoader />
                        ) : (
                          t("new-password.form.btn-guardar")
                        )}
                      </Button>
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <h2>{t("validate.token.expired.title")}</h2>
                  <p>{t("validate.token.expired.text")}</p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
